/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
    padding: 0;
    width: 280px;
}

/** Classes for the displayed toast **/
.Toastify__toast {
    padding: 0;
    min-height: unset;
    background: transparent;
}

.Toastify__toast-body {
    padding: 0;
    margin: 0;
}
.MuiAlert-filledError {
    background-color: #f44336 !important;
}
